export const createResponseError = (message: string, options: ErrorOptions & { data?: Record<string, unknown> }) => {
    const { data, ...errorOptions } = options || {};
    const error = new ResponseError(message, errorOptions);
    error.data = data;
    return error;
};

export class ResponseError extends Error {
    data?: Record<string, unknown>;
}
